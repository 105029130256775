// import React from 'react'
// import { Link, Redirect } from 'react-router-dom';

function Posts(props) {
    return (
        <div style={{ padding: 10 }}>
            <div >
                <h1>Posts</h1>
            </div>
        </div>
    )
}

export default Posts