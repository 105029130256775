import React from 'react';
import './404.scss';

function NotFound(props) {
    return (
        <div className="notfound">
            <div className="container">
                <h1>Page not found</h1>
            </div>
        </div >
    )
}

export default NotFound;